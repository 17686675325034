

import { Component, Vue, Mixins } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";
import stores from "@/store/store";
const store: any = stores;

@Component
export default class Import3Component extends Vue {
  public fileExtension = null;
  public extensionNotMatch = null;

  /******************************************************************************
   *                   On Draging a file                                        *
   ******************************************************************************/
  public dragFile(event) {
    let fnmFile = event.dataTransfer.files[0];
    if (!fnmFile) return;
    let extensionNotMatch = this.checkExtension(fnmFile);
    if (extensionNotMatch) return;
    this.uploadFnmFile(fnmFile);
  }

  /******************************************************************************
   *                   On Selecting a file                                      *
   ******************************************************************************/
  public selectFile(event) {
    let fnmFile = event.target.files[0];
    if (!fnmFile) return;
    let extensionNotMatch = this.checkExtension(fnmFile);
    if (extensionNotMatch) return;
    this.uploadFnmFile(fnmFile);
  }
  /***********************************************************************************
   *            On import 3.2                                                        *
   *            * Function working   *                                                *
   *            saving ediFile in borrowers collection                               *
   *            fetching dontAsk field based on usertype                             *
   *            if usertype is broker then fetch it from broker collection           *
   *            else if usertype is MLo then fetch it from Mlo collection            *                                                           *
   ***********************************************************************************/
  public async uploadFnmFile(fnmFile) {
    this.$store.state.wemloLoader = true;
    try {
      let formData = new FormData();
      formData.append("file", fnmFile);
      let response = await Axios.post(
        BASE_API_URL + "loan/uploadFnmEdiFile",
        formData,
        {
          params: {
            statusId: 1,
            loanTxnId:
              this.$route.query.source == "import-3.2"
                ? "null"
                : this.$route.query.id,
            userType: this.$userType,
            source: this.$route.query.source
          }
        }
      );
      this.$store.state.wemloLoader = false;
      if (this.$route.query.source != "import-3.2") {
        this.$router.push({
          path: this.$getCurrentUserTypePath("compare-borrowerInfo"),
          query: {
            id: this.$route.query.id,
            source: this.$route.query.source,
            ediFile: response.data[0].path
          }
        });
      } else {
        this.$router.push({
          path: this.$getCurrentUserTypePath("add-fnm-borrowerInfo"),
          query: {
            id: this.$route.query.id,
            source: this.$route.query.source,
            ediFile: response.data[0].path
          }
        });
      }
    } catch (error) {
      this.$store.state.wemloLoader = false;

      console.log(error);
    }
  }
  /********************************************************************************
   *     To check the extension of file only fnm and txt file is allowed          *
   ********************************************************************************/
  public checkExtension(file) {
    this.fileExtension = file.name.split(".").pop();
    if (
      this.fileExtension == "fnm" ||
      this.fileExtension == "FNM" ||
      this.fileExtension == "txt" ||
      this.fileExtension == "TXT"
    ) {
      this.extensionNotMatch = false;
    } else {
      this.extensionNotMatch = true;
    }
    return this.extensionNotMatch;
  }

  async mounted() {}
}
