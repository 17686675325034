

import { Component, Vue } from "vue-property-decorator";

@Component
export default class DashboardComponent extends Vue {
  public submit(source) {
    try {
      this.$router.push({
        path: this.$getCurrentUserTypePath(
          source == "import-3.2" || source == "import-3.4"
            ? source
            : "add-borrower-info"
        ),
        query: { source: source, enumerationType: 'mismo'},
      });
    } catch (error) {
      console.log(error);
    }
  }
}
