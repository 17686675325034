



































































































import {
    Component,
    Vue,
    Prop
} from "vue-property-decorator";
import {
    BASE_API_URL
} from "@/config";
import Axios from "axios";

@Component({
    components: {}
})
export default class Appraisal extends Vue {

    private loanTxnId: String = null;
    private vendorLists: Array < any >= [];
    private vendorComp: any = {};
    private isSelectVendor: boolean = false
    private addwemVendor: any = {}
    private appraisal: String = "Appraisal";
    private inProcess: boolean = false;
    private appraisalTime: String = null
    public async addWemloVendor() {

    }
    public async submit() {
        try {
            /**saving appraisal company & appraisal time**/
            if (this.vendorLists.length > 0) {
                if (!this.vendorComp.hasOwnProperty('_id')) {
                    this.isSelectVendor = true
                    return false
                }
                let formValid = await this.$validator.validateAll();
                if (!formValid) {
                    return false;
                }
                let response = await Axios.post(BASE_API_URL + 'broker/getAppraisalList', {
                    loanTxnId: this.$route.query.id,
                    identifier: "save",
                    vendor: this.vendorComp,
                    appraisalTime: this.appraisalTime
                })
                this.isSelectVendor = false
            }
            /**adding new appraisal company**/
            else {
                let formValid = await this.$validator.validateAll("venderInfo");
                if (!formValid) {
                    return false;
                }
                this.inProcess = true;
                let response = await Axios.post(
                    BASE_API_URL + "wemloStaff/getWemloVendors", {
                        wemloVendorData: this.addwemVendor,
                        appraisal: this.appraisal,
                        userId: this.$userId,
                        userType: this.$userType,
                        source: "submission form",
                        loanTxnId: this.$route.query.id
                    });
                this.inProcess = false;

            }
            this.$snotify.success("Appraisal saved successfully");
        } catch (error) {
            this.$snotify.error("Something went wrong! Please try after sometime.");
            console.log(error);

            /**
             * Must return false if throw some error
             */
            return false;
        }
    }

    /*****Fetching lender specific apraisal company if exist*****/
    private async fetchAppraisalList() {
        let response = await Axios.post(
            BASE_API_URL + "broker/getAppraisalList", {
                loanTxnId: this.$route.query.id,
                identifier: "fetch"
            });
        this.vendorLists = response.data.vendors;

        if (response.data.vendorId && response.data.vendorId != "")
            this.vendorComp = {
                _id: response.data.vendorId
            }

        if (response.data.appraisalTime && response.data.appraisalTime != "")
            this.appraisalTime = response.data.appraisalTime

    }

    async mounted() {
        this.loanTxnId = String(this.$route.query.id);
        this.fetchAppraisalList();
    }
}
