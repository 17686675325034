


import {
    Component,
    Vue
} from "vue-property-decorator";
import {
    BASE_API_URL
} from "@/config";
import Axios from "axios";
import vueCustomScrollbar from "vue-custom-scrollbar";
/**
 * Importing Child components
 */
import DisplayFiles from "@/views/DisplayDocument.vue";
import Lender from "./Steps/Lender.vue";
import CloseDate from "./Steps/CloseDate.vue";
import Fees from "./Steps/Fees.vue";
import Appraisal from "./Steps/Appraisal.vue";
import TitleCo from "./Steps/TitleCo.vue";
import LoanEstimates from "@/views/loan-estimate/LoanEstimate.vue";
import Disclosures from "./Steps/Disclosures.vue";
import Processing from "./Steps/Processing.vue";
import Submission from "./Steps/Submission.vue";
import _ from "lodash";

import {LOAN_FLOW_STEPS} from "@/constants";

@Component({
    components: {
        vueCustomScrollbar,
        Lender,
        CloseDate,
        Fees,
        Appraisal,
        TitleCo,
        LoanEstimates,
        Disclosures,
        Processing,
        Submission
    }
})
export default class SubmissionFormComponent extends Vue {
    private currentState: String = null;
    private stepper = Array;
    public changesInLoanEstimate = false;
    private loanTxnId: String = null;
    public borrowerData: any = {};
    public borrowerName: String = '';

    private currentStep: String = null;
    private inProcess: boolean = false;
    private lastCalled: number = null;
    private disclosuresEnabled: boolean = true;

    public loanSteps = LOAN_FLOW_STEPS;

    private async renderComponent(step) {
        try {
            if (!step) throw new Error("Step is undefined");
            // let obj = {
            //   1: "lender",
            //   2: "closeDate",
            //   3: "fees",
            //   //4: "appraisal",
            //   4: "titleCo",
            //   5: "loanEstimates",
            //   6: "disclosures",
            //   7: "processing",
            //   8: "submission"
            // };

            // if (!obj.hasOwnProperty(step)) throw new Error("Invalid Step receive");
            this.currentState = step;
            this.currentStep = step;

           // if (step == 'processing') this.test();
        } catch (error) {
            console.log(error);
            this.renderComponent('lender');
        }
    }

    public isStepComplete(step) {
        let currentStepIdx = this.loanSteps.findIndex(stp => {
            return stp.stepId == step.stepId;
        })
        let activeStepIdx = this.loanSteps.findIndex(stp => {
            return this.currentStep == stp.stepId;
        })
        return currentStepIdx <= activeStepIdx;
    }

    private async test() {
        try {
            setTimeout(() => {
              //  this.$refs.child["pushComponents"](["FlagsterGiftLetter"]);
            }, 200);
        } catch (error) {
            console.log(error);
        }
    }

    public async currentSubmissionStep() {
        try {
            let response = await Axios.post(
                BASE_API_URL + "broker/currentSubmissionStep", {
                    loanTxnId: this.$route.query.id
                });

            if (response.data.data === 'disclosures' && !this.disclosuresEnabled) {
                response.data.data = 'loanEstimates';
            }

            this.renderComponent(response.data.data);
        } catch (error) {
            console.log(error);
        }
    }

    private async updateStepAndSave(direction) {
        try {
            let step = this.getStepIndex(direction);
            await this.$store.dispatch("updateSubmissionForm", {
                loanTxnId: this.loanTxnId,
                currentState: step
            });
            this.renderComponent(step);
        } catch (error) {
            console.log(error);
        }
    }

    private getStepIndex(mov) {
        let stepIndex = this.loanSteps.findIndex(stp => {
            return this.currentStep == stp.stepId;
        })
        let nextIndex = mov == 1 ? -1 : +1;
        stepIndex = stepIndex + nextIndex;
        return this.loanSteps[stepIndex].stepId
    }

    private goBack() {
        if (this.currentState == 'lender') {
            this.$router.push({
                path: this.$getCurrentUserTypePath("broker-loan-documents"),
                query: {
                    id: this.$route.query.id,
                    edit: "true"
                }
            });
        } else {
            this.updateStepAndSave(1);
        }
    }
    private submit(toNextStep = true) {
        try {
            let date = new Date();
            let now = date.getTime();
            let limit = 700; // minimum milliseconds between calls
            if (!this.lastCalled || now - this.lastCalled > limit) {
                this.lastCalled = now;
                this.submitData(toNextStep);
            } else {
                return;
            }
        } catch (error) {
            console.log(error);
        }
    }
    private async submitData(toNextStep) {
        try {
            this.inProcess = true;
            if (this.currentStep !== 'submission') {
                let save = await this.$refs.child["submit"]();
                if (!save) {
                    this.inProcess = false;
                    return;
                }
                if (toNextStep) {
                  await this.updateStepAndSave(2);
                }
            } else {
                this.$router.push({
                    path: this.$getCurrentUserTypePath("blank-backGround-popUp"),
                    query: {
                        id: this.$route.query.id,
                        modalName: "documentRequestModal",
                        source: "Submisssion Form"
                    }
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.inProcess = false;
        }
    }

    // public showValidation(message) {
    //   if (message) this.errorMessage = message;
    // }

    /**
     * Checking if Broker made any changes in 1003 form then we are notifying Broker that you made some changes in 1003.
     * Please check Loan Estimate form
     */
    public async ifThereIsChanges() {
        try {
            let response = await Axios.post(
                BASE_API_URL + "titleOrder/ifThereIsChangesInLe", {
                    loanTxnId: this.loanTxnId
                });
            console.log(response);

            if (response.data && response.data.changesInLE && response.data.changesInLE.length > 0) {
                this.changesInLoanEstimate =
                    response.data.changesInLE.length > 0 ? true : false;
                let changed = "";
                response.data.changesInLE.forEach(e => {
                    changed += e.name + " ";
                });
                this.$snotify.info(
                    "you made changes in some places like \n" + changed
                );
            }
        } catch (error) {
            console.log(error);
        }
    }

    public async getBorrowerInfo() {
        try {
            let response = await Axios.post(
                BASE_API_URL + "borrower/getBorrowerInfo", {
                    loanTxnId: this.loanTxnId,
                    userId: this.$userId
                }
            );

            if (response.status == 200) {
                this.borrowerData = response.data;
            }

            const borrowerInfo = _.get(this.borrowerData, 'borrowerInfo[0].personalInfo.name') || {};
            const {
                firstName,
                lastName
            } = borrowerInfo || {};
            this.borrowerName = `${firstName} ${lastName}`;
        } catch (error) {
            console.log(error);
        }
    }

    private buttonPosition(step) {
        if (step == 'closeDate') return "closing-date-center mt-3";
        else if (step == 'fees') return "closing-date-center mt-3";
        else if (step == 'titleCo') return "closing-date-center mt-3";
        else if (step == 'loanEstimates') return "closing-date-center mt-3";
        else return "";
    }
    private bottomSection() {
        if (this.currentStep == 'loanEstimates') return 'col-lg-11 col-md-12 col-sm-12 col-12 m-auto padding-0-sm btn-colun-width';
        if (this.currentStep == 'processing') return ' col-12 card_bg radius__border-5 mb-3 row_customize align-items-center processor-submission ';
        if (this.currentStep == 'fees') return 'col-lg-12 col-md-12 col-sm-12 col-12 m-auto p-0';
    }

    private getButtonText() {
        if (this.currentStep != 'loanEstimates' && this.currentStep != 'processing') return this.inProcess ? "PROCESSING" : "CONTINUE";
        else if (this.currentStep == 'processing') return this.inProcess ? "SUBMITTING" : "SUBMIT LOAN";
        else if (this.currentStep == 'loanEstimates') return this.inProcess ? "PROCESSING" : "APPROVE FEE WORKSHEET AND CONTINUE";
    }

    async doesLoanUsesSmartDocs(loanTxnId) {

        const { data: { usesSmartDocs } } = await Axios.post(
            BASE_API_URL + "document/doesLoanUsesSmartDocs",
            { loanTxnId: this.loanTxnId, }
        );

        return usesSmartDocs;
    }
    
    async mounted() {
        this.loanTxnId = String(this.$route.query.id);

        this.disclosuresEnabled = await this.doesLoanUsesSmartDocs(this.loanTxnId);

        this.loanSteps.find((step) => step.stepId === 'disclosures').hidden = !this.disclosuresEnabled;
        this.loanSteps = this.loanSteps.filter(a => !a.hidden);

        await this.currentSubmissionStep();
        await this.getBorrowerInfo();
        // await this.ifThereIsChanges();
    }
}
