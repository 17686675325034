




















































































































































































    import {
        Component,
    } from "vue-property-decorator";
    import {
        BASE_API_URL
    } from "@/config";
    import Axios from "axios";
    import {
        LENDER_TABS
    } from "@/constants";
import MailLender from "@/views/sendMailLender.vue";
import CommonMixin from "@/mixins/common.mixin";
import ConfirmModal from "@/components/urla/los/generic/ConfirmModal.vue";

    @Component({
        components: {MailLender, ConfirmModal}
    })
    export default class Lender extends CommonMixin {
        private loanTxnId: String = null;
        private lenderList: Array < any > = [];
        private totalCount: number = 0;
        private lenderLoader: boolean = false;
        private lenderType: String = null;
        private tabName: Array < any > = LENDER_TABS;
        private currentTab: String = "Integrated Lenders";
        private addNewLender: boolean = false;
        private lenderInfo: any = {};
        private isSelectLender: boolean = false
        private isAddLender: boolean = false
        private lenderId: any = null;
        public openSendModel: any = false;
        public openDisableNotifyModel : any = false;

        public get confirmModalMessage() {
          return 'A lender was selected with Run AUS Interim/Final Status and a Case File ID exists. Please click No to cancel and proceed with the previously selected lender, or Yes to continue with the lender change and reset the AUS Case File ID.\n\nAre you sure you want to delete the AUS Case File ID?'
        }

        /*********For saving/adding lenders for specific loan *********/
        private async submit() {
          try {
            const validation = this.validateRunAusSelectedLender();
            if (!validation) return false;

            const formValid = await this.$validator.validateAll();
            if (!formValid) {
              return false;
            }

            if (!this.lenderInfo.hasOwnProperty('lenderName')) {
              if (this.currentTab != 'Other Lenders' && this.lenderList.length > 0) this.isSelectLender = true
              else this.isAddLender = true

              return false
            }

            await Axios.post(
              BASE_API_URL + "broker/submissionFormLender", {
                  loanTxnId: this.$route.query.id,
                  identifier: this.currentTab,
                  lenderInfo: this.lenderInfo
              });

                this.$snotify.success("Lender saved successfully");
                return true;
            } catch (error) {
                this.$snotify.error("Something went wrong! Please try after sometime.");
                console.log(error);

            /**
              * Must return false if throw some error
              */
            return false;
          }
        }

        public validateRunAusSelectedLender() {
          if (this.lenderInfo.isRunAus) {
            const lender = this.lenderList.find((l) => l._id === this.lenderInfo.brokerLenderId || l._id === this.lenderInfo._id);
            if (lender && ((lender.hasOwnProperty('isCredentialVerified') && !lender.isCredentialVerified) || !lender.lenderId)) {
              if (!lender.userName || !lender.password) {
                console.log('no credentials');
                this.$modal.show('noCredentialsModal');
              } else {
                this.openModelAndSendMail(lender);
              }
              return false;
            }
          }

          return true;
        }

        /**********Getting lender list **********/
        private async getLenders(callFrom) {
            try {
              const sort = (a, b) => {
                return (a.lenderName > b.lenderName) ? 1 : -1
              };

              this.lenderList = [];
                this.$store.state.wemloLoader = true;
                let response = await Axios.get(
                    BASE_API_URL +
                    "broker/getLenderDetails/" +
                    this.$userId +
                    "/" +
                    this.$userType +
                    "/" +
                    this.$brokerId +
                    "/" +
                    this.lenderType +
                    "/" +
                    this.loanTxnId +
                     "/" +
                    callFrom

                    );
                this.lenderList = response.data.lenders;
                if(this.lenderList.length){
                  let disabledSortedListed = this.lenderList.filter(item => !item.lenderId ).sort(sort);
                  let enabledSortedListed = this.lenderList.filter(item => item.lenderId ).sort(sort);
                  this.lenderList = enabledSortedListed.concat(disabledSortedListed);
                }
                if (this.lenderList.length > 0 && response.data.savedLender &&
                    response.data.savedLender._id && callFrom=='mount') {
                        this.lenderInfo = {
                          _id: response.data.savedLender._id,
                          lenderName: response.data.savedLender.lenderName,
                          isRunAus: response.data.savedLender.isRunAus,
                          brokerLenderId: response.data.savedLender.brokerLenderId
                        }
                    const tabObj = this.getCurrentTab(response.data.savedLender.isIntegrated, response.data.savedLender.isWemloApproved);
                    this.tabChange({class:'active',name:tabObj.tab}, tabObj.index, 'tabChange');
                }
                this.$store.state.wemloLoader = false;
            } catch (error) {
                 this.$store.state.wemloLoader = false;
                console.log(error);
            }
        }

        getCurrentTab(isIntegrated, isWemloApproved) {
          if(isIntegrated && isWemloApproved) {
            return {tab:'Integrated Lenders', index:0};
          } else if(!isIntegrated && isWemloApproved) {
            return {tab:'Other Lenders',index:1};
          } else {
            return {tab:'Custom Lenders',index:2};
          }
        }

        /*******Changing tab of lender type******/
        private async tabChange(tab, index, callfrom) {
            for (let i = 0; i < this.tabName.length; i++) {
                this.currentTab = tab.name;
                if (i == index) this.tabName[i].class = "active";
                else this.tabName[i].class = "";
            }
            this.lenderType =  tab.name;
            if (tab.name) {
                await this.getLenders(callfrom)
            }
            this.isSelectLender = false
            this.isAddLender = false
                //this.lenderList = []
        }


        public playVideo() {
            this.$router.push({
                path: this.$getCurrentUserTypePath("blank-backGround-popUp"),
                query: {
                    id: this.$route.query.id,
                    modalName: "documentRequestModal",
                    source: "Lender page"
                }
            });
        }

        public openModelAndSendMail(lender) {

            // this.$modal.show("openLenderModel");
            if(!lender.userName) this.openDisableNotifyModel = true;
            else this.openSendModel = true;

            this.lenderId = lender
        }
          public async refreshData(data) {
            this.openSendModel = false;
            this.openDisableNotifyModel = false;
            // await this.getLenders('mount');
          }
        private getClass(lender) {
            return this.lenderInfo != {} ? (lender._id == this.lenderInfo._id || lender._id == this.lenderInfo.brokerLenderId) : false
        }
        private getClassType(lender) {
          return ((!this.lenderInfo.isRunAus && !lender.userName) || (this.lenderInfo.isRunAus && (lender._id != this.lenderInfo._id && lender._id != this.lenderInfo.brokerLenderId))) ? 'flex-item-box flex-item-box-disable' : 'flex-item-box'
        }

        private async loadImage(paths, i, lender) {
            try {
                let response = await Axios.post(BASE_API_URL + 'common/fetchDocument', {
                    path: paths
                });
                let dom = document.getElementById(i != undefined ? 'imageEl' + i : 'imageEl');
                if(!dom) { return; }
                dom['src'] = 'data:image/' + paths.split('.').pop() + ';base64,' + response.data;
                dom.append();
               } catch (error) {
                console.log(error);
                lender.profilePicture = null;
            }
        }

        public async resetAusCaseFile() {
          try{
            this.$store.state.wemloLoader = true;
            await this.resetAusCaseFileId();
            this.lenderInfo = {};
            this.$store.state.wemloLoader = false;
          } catch(error) {
            this.$store.state.wemloLoader = false;
            console.log(error);
          }
        }

        public showConfirmModal() {
          this.$modal.show("confirmModal");
        }

        public confirmModalYes() {
          this.$modal.hide("confirmModal");
          this.resetAusCaseFile();
        }

        public confirmModalNo() {
          this.$modal.hide("confirmModal");
        }

        public selectLender(lender) {
          if(this.lenderInfo.isRunAus) {
            return;
          }
          if(((lender.hasOwnProperty('isCredentialVerified')&&!lender.isCredentialVerified) || !lender.lenderId)) {
            this.openModelAndSendMail(lender)
          } else if(!lender.lenderId) {
            this.lenderInfo={}
          } else {
            this.lenderInfo=lender;
          }
        }

        public hideModel(){
          this.$modal.hide('noCredentialsModal');
        }

        async mounted() {
            this.lenderType = "Integrated Lenders";
            this.loanTxnId = String(this.$route.query.id);
            await this.getLenders('mount');
        }
    }
