
























































import { Component, Vue, Mixins } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";
import stores from "@/store/store";
const store: any = stores;

@Component
export default class ImportMismoComponent extends Vue {
  public extensionMatch = true;

  public selectFile(event) {
    const file = event.target.files[0] || event.dataTransfer.files[0];
    if (file && this.checkExtension(file)) {
      this.uploadfile(file);
    }
  }

  public async uploadfile(file) {
    this.$store.state.wemloLoader = true;
    try {
      let formData = new FormData();
      formData.append("file", file);
      const response = await Axios.post(
        BASE_API_URL + "loan/uploadMismoEdiFile",
        formData,
        {
          params: {
            statusId: 1,
            loanTxnId:
              this.$route.query.source == "import-3.4"
                ? "null"
                : this.$route.query.id,
            userType: this.$userType,
            source: this.$route.query.source,
          }
        }
      );
      this.$store.state.wemloLoader = false;

      this.$router.push({
        path: this.$getCurrentUserTypePath(
          this.$route.query.source == "import-3.4"
            ? "add-mismo-borrowerInfo"
            : "compare-borrowerInfo"
        ),
        query: {
          id: this.$route.query.id,
          source: this.$route.query.source,
          ediFile: response.data[0].path,
        },
      });
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  public checkExtension(file) {
    this.extensionMatch = file.name.split(".").pop().toLowerCase() == "xml";
    return this.extensionMatch;
  }

  async mounted() {}
}
