
































import {
    Component,
    Vue,
    Prop
} from "vue-property-decorator";
import Axios from 'axios';
import {
    BASE_API_URL
} from "@/config";
import Calendar from 'v-calendar/lib/components/calendar.umd';
import Datepicker from 'v-calendar/lib/components/date-picker.umd'
import moment from "moment";

@Component({
    components: {
        Datepicker,
        Calendar
    }
})
export default class CloseDate extends Vue {

    private closingDate: Date = null;
    private loanTxnId: String = null;

    /******Getting closing date if already saved*****/
    private async getClosingDate() {
        try {
            this.$store.state.wemloLoader = true;

            let res = await Axios.post(BASE_API_URL + 'broker/saveClosingDate', {
                loanTxnId: this.$route.query.id,
                identifier: "fetch"
            })
            this.closingDate = res.data.closingDate == null ? null : new Date(res.data.closingDate);
            this.$store.state.wemloLoader = false;

        } catch (error) {
            this.$store.state.wemloLoader = false;
            console.log(error);
        }
    }

    public onChangeClosingDate(closingDate) {
        if (closingDate instanceof Date) {
            this.closingDate = moment(closingDate).utc().hours(12).minutes(0).toDate();
        }
    }

    /******Saving  closing date *****/
    private async submit() {
        try {
            let formValid = await this.$validator.validateAll();
             if (!formValid) {
                return false;
            }

            await Axios.post(BASE_API_URL + 'broker/saveClosingDate', {
                loanTxnId: this.$route.query.id,
                closingDate: this.closingDate,
                identifier: "save"
            })
            this.$snotify.success("Closing date saved successfully");
             return true;
        } catch (error) {
            this.$snotify.error("Something went wrong! Please try after sometime.");
            console.log(error);

            /**
             * Must return false if throw some error
             */
            return false;
        }
    }

    async mounted() {
        this.loanTxnId = String(this.$route.query.id);
        this.getClosingDate();
    }
}
