

import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";

import {
  AUS,
  PRODUCT_TYPE,
  PROPERTY_TYPE,
  OCCUPANCY,
  AMORTIZATION_TYPE,
  LOAN_PURPOSE,
  ESCROW_TYPE,
  LOAN_TERM,
  DOCUMENT_TYPE,
  PRE_PAY_PENALTY,
  INVESTOR_OPTION,
  EMPLOYMENT_TYPE
} from "@/constants";
import {borrowerData,} from "@/models/borrowerData.model";
import stores from "@/store/store";
import { FinancialInfo } from "@/models/pos/financial-info.model";

const store: any = stores;
@Component({ components: {} })
export default class PricingComponent extends Vue {
  public borrowerData: any = new borrowerData();
  public AUS = AUS;
  public documentType = DOCUMENT_TYPE;
  public prePayPenalty = PRE_PAY_PENALTY;
  public employment = EMPLOYMENT_TYPE;
  public investorOption = INVESTOR_OPTION;
  public productType = PRODUCT_TYPE;
  public propertyType = PROPERTY_TYPE;
  public occupancy = OCCUPANCY;
  public amortizationType = AMORTIZATION_TYPE;
  public loanPurpose = LOAN_PURPOSE;
  public escrowType = ESCROW_TYPE;
  public loanTerm = LOAN_TERM;
  public stateCountyLoader = false;
  public loanStateList = [];
  public countiesApi = [];
  public borrowerStateList = [];
  public brokerProfilePicture = null;
  public dotLoader = true;
  public pvError = null;
  public laError = null;
  public minDpError = null;
  public showAllFields = true;
  public inProcessRunPricing = false;
  public inProcessPrequal = false;
  public inProcessSaveLead = false;
  public financialInfo = new FinancialInfo();
  public get stateList() {
    return store.state.sessionObject.states;
  }

  //Sending the loan data to server
  public async getBorrower() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "loan/getDataForLoan/" + this.$route.query.id);
      if (response.data) {
        this.borrowerData = response.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
  public async saveNewLoan(type) {
    try {
      if (type == "runPricing" || type == "prequal") {
        this.inProcessRunPricing = true;
        let formValid = await this.$validator.validateAll();
        if (
          !formValid ||
          this.borrowerData.loanInformation.rate > 100 ||
          this.minDpError ||
          this.laError
        ) {
          this.inProcessRunPricing = false;
          this.inProcessPrequal = false;
          this.inProcessSaveLead = false;
          return;
        }
      }
      if (type == "saveLead") this.inProcessSaveLead = true;

      let body = {
        borrowerData: this.borrowerData,
        loanTxnId: this.$route.query.id,
        userId: this.$userId,
        type: type,
        financialInfo: this.financialInfo.realEstate.properties[0]
      };
      let response = await Axios.post(BASE_API_URL + "loan/saveNewLoan", body);

      if ((response.data.msg = "success")) {
        if (type == "prequal") {
          if (this.brokerProfilePicture != null) {
            this.$snotify.success("Data submitted");
            this.$router.push({
              path: this.$getCurrentUserTypePath("pre-qualification"),
              query: { loanTxnId: this.$route.query.id }
            });
          } else {
            this.$snotify.info(
              "Please upload logo to generate Pre-Qual letter"
              // "Data Saved"
            );
          }
        }
        if (type == "runPricing") {
          this.$snotify.success("Data submitted");
          this.$router.push({
            path: this.$getCurrentUserTypePath("ppe-results"),
            query: { loanTxnId: body.loanTxnId }
          });
        }
        if (type == "saveLead") {
          this.$snotify.success("Data submitted");
          this.$router.push({
            path: this.$getCurrentUserTypePath("blank-backGround-popUp"),
            query: {
              id: body.loanTxnId,
              modalName: "savelead",
              source: "Price My Loan"
            }
          });
        }
        this.inProcessRunPricing = false;
        this.inProcessPrequal = false;
        this.inProcessSaveLead = false;
      }
    } catch (error) {
      this.inProcessRunPricing = false;
      this.inProcessPrequal = false;
      this.inProcessSaveLead = false;
      console.log(error);
    }
  }
  /**
   * Handle the condition for toggling between price and rate
   */
  public togglePriceRate(type) {
    if (type == "price" && this.borrowerData.loanInformation.price) {
      this.borrowerData.loanInformation.rate = 0;
    } else if (this.borrowerData.loanInformation.rate) {
      this.borrowerData.loanInformation.price = 0;
    }
  }

  public handleFICO(checked) {
    this.borrowerData.loanInformation.FICO = checked
      ? 0
      : this.borrowerData.loanInformation.FICO;
  }

  public checkIfRequired(type) {
    if (
      !this.borrowerData.loanInformation.rate &&
      !this.borrowerData.loanInformation.price
    ) {
      return true;
    } else {
      if (type == "price") {
        if (this.borrowerData.loanInformation.rate) {
          return false;
        } else {
          return true;
        }
      } else {
        if (this.borrowerData.loanInformation.price) {
          return false;
        } else {
          return true;
        }
      }
    }
  }
  async setLtvDownPaymentOrLoanAmount(type, calledFrom = "other") {
    try {
      let pv: any = this.borrowerData.loanInformation.propertyValue;
      let mdp: any = this.borrowerData.loanInformation.minDownPayment;
      let dpt: any = this.borrowerData.loanInformation.minDownPaymentType;
      let la: any = this.borrowerData.loanInformation.loanAmount;
      this.pvError = null;
      this.minDpError = null;
      this.laError = null;

      if (pv != 0) {
        if (type == "setDownPayment" && pv > la) {
          if (dpt == "$") {
            mdp = this.borrowerData.loanInformation.minDownPayment = pv - la;
          } else {
            mdp = this.borrowerData.loanInformation.minDownPayment =
              ((pv - la) / pv) * 100;
          }
        }

        if (type == "setLoanAmount" && pv > mdp) {
          if (dpt == "$") {
            la = this.borrowerData.loanInformation.loanAmount = pv - mdp;
          } else {
            if (mdp > 100) {
              this.minDpError =
                "Minimum down payment cannot be more than 100 %";
              //return;
            } else {
              la = this.borrowerData.loanInformation.loanAmount =
                pv - (pv * mdp) / 100;
            }
          }
        }

        if (la > pv) {
          if (calledFrom == "propertyValue") {
            this.pvError = "Property value cannot be less than loan amount.";
          } else {
            this.laError = "Loan amount cannot be more than property value.";
          }
          mdp = this.borrowerData.loanInformation.minDownPayment = 0;
          // return;
        } else if (mdp > pv) {
          if (calledFrom == "propertyValue") {
            mdp = this.borrowerData.loanInformation.minDownPayment = pv - la;
          } else {
            this.minDpError =
              "Minimum down payment cannot be more than property value.";
          }
          // return;
        }
        let ltv = (la / pv) * 100;
        ltv = isNaN(ltv) || !isFinite(ltv) ? 0 : Math.round((ltv * 100) / 100);
        this.borrowerData.loanInformation.LTV = ltv;
        this.borrowerData.loanInformation.CLTV = ltv;
      } else {
        this.borrowerData.loanInformation.minDownPayment = 0;
      }
    } catch (error) {
      console.log(error);
    }
  }
  public async getBrokerDetails() {
    this.dotLoader = true;
    try {
      let response = await Axios.get(BASE_API_URL + "broker/getBrokerDetails", {
        params: { brokerId: this.$brokerId }
      });
      this.brokerProfilePicture = response.data.brokerDetail.profilePicture;
      this.dotLoader = false;
    } catch (error) {
      this.dotLoader = false;
      console.log(error.response);
    }
  }

  public getCounties(state) {
    this.countiesApi = [];
    let seletedStateData = this.stateList.filter(a => a.state == state.name);
    this.countiesApi = seletedStateData[0].counties;
  }
  public onStateChange() {
    this.borrowerData.loanInformation.county = "";
    let state = this.borrowerData.loanInformation.state;
    this.stateList.find(data => {
      if (state && data.code == state.code) {
        this.countiesApi = data.counties;
      }
    });
  }
  public async setStateCounty(mountCall: boolean) {
    try {
      if (
        !(await this.$validator.validate(
          "loanZip",
          this.borrowerData.loanInformation.zipCode
        ))
      )
        return;
      this.stateCountyLoader = true;
      let response = await Axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json?sensor=true&key=AIzaSyC4z_vM_4vNFs5_KH5wUdTjJrP8yLS9lr8&components=country:US|postal_code:" +
          this.borrowerData.loanInformation.zipCode,
          { headers: { public: true}}
      );
      await this.setStateList(response, "loan", mountCall);
      this.stateCountyLoader = false;
    } catch (error) {
      this.stateCountyLoader = false;
      console.log(error.response);
    }
  }
  public async setStateList(response: any, type: string, mountCall: boolean) {
    let findState: any = null;
    let findCounty: any = null;
    if (type == "loan") {
      this.loanStateList = [];
      this.countiesApi = [];
    } else {
      this.borrowerStateList = [];
    }
    if (response.data.status == "OK") {
      response.data.results[0]["address_components"].forEach(d => {
        if (d.types[0] == "administrative_area_level_1") {
          findState = d;
        }
        if (d.types[0] == "administrative_area_level_2") {
          findCounty = d.long_name;
        }
        if (type == "borrower") {
          if (d.types.includes("locality")) {
            this.borrowerData.basicInformation.borrower.address.city =
              d.long_name;
          } else if (
            !this.borrowerData.basicInformation.borrower.address.city &&
            d.types.includes("administrative_area_level_3")
          ) {
            this.borrowerData.basicInformation.borrower.address.city =
              d.long_name;
          }
        }
      });
      if (!findState) {
        this.stateList.forEach(data => {
          this.loanStateList.push(data);
        });
      } else {
        if (type == "loan") {
          this.loanStateList.push({
            state: findState.long_name,
            code: findState.short_name
          });
          this.borrowerData.loanInformation.state = {
            name: findState.long_name,
            code: findState.short_name
          };
        } else {
          this.borrowerStateList.push({
            state: findState.long_name,
            code: findState.short_name
          });
          this.borrowerData.basicInformation.borrower.address.state = {
            name: findState.long_name,
            code: findState.short_name
          };
        }
      }
      if (type == "loan") {
        if (!findCounty) {
          if (!mountCall) this.borrowerData.loanInformation.county = "";
          let state = this.borrowerData.loanInformation.state;
          this.stateList.find(data => {
            if (state && state.code == data.code) {
              this.countiesApi = data.counties;
            }
          });
        } else {
          this.countiesApi.push(findCounty);
          this.borrowerData.loanInformation.county = findCounty;
        }
      }
    }
    if (response.data.status == "ZERO_RESULTS") {
      this.stateList.forEach(data => {
        if (type == "loan") this.loanStateList.push(data);
        else this.borrowerStateList.push(data);
      });
      if (type == "loan" && this.borrowerData.loanInformation.state.code) {
        this.getCounties(this.borrowerData.loanInformation.state);
      } else {
        if (!mountCall) {
          this.borrowerData.basicInformation.borrower.address.city = null;
          this.borrowerData.basicInformation.borrower.address.state = null;
        }
      }
    }
  }
  //Setting  loanInformation default value
  public setDefaultValues() {
    if (!this.borrowerData.loanInformation.productType) {
      this.borrowerData.loanInformation.productType = this.productType[0];
    }
    if (!this.borrowerData.loanInformation.amortizationType) {
      this.borrowerData.loanInformation.amortizationType = this.amortizationType[0];
    }
    if (!this.borrowerData.loanInformation.term) {
      this.borrowerData.loanInformation.term = this.loanTerm[4];
    }
    if (!this.borrowerData.loanInformation.occupancy) {
      this.borrowerData.loanInformation.occupancy = this.occupancy[2];
    }
    if (!this.borrowerData.loanInformation.propertyType) {
      this.borrowerData.loanInformation.propertyType = this.propertyType[0];
    }
    if (!this.borrowerData.loanInformation.AUS) {
      this.borrowerData.loanInformation.AUS = this.AUS[0];
    }
    if (!this.borrowerData.loanInformation.escrows) {
      this.borrowerData.loanInformation.escrows = this.escrowType[0];
    }
    if (!this.borrowerData.loanInformation.purpose) {
      this.borrowerData.loanInformation.purpose = this.loanPurpose[0];
    }
    if (!this.borrowerData.loanInformation.doctype) {
      this.borrowerData.loanInformation.doctype = this.documentType[0];
    }
    if (!this.borrowerData.loanInformation.prepayPenalty) {
      this.borrowerData.loanInformation.prepayPenalty = this.prePayPenalty[5];
    }
    if (!this.borrowerData.loanInformation.investorOptions) {
      this.borrowerData.loanInformation.investorOptions = this.investorOption[0];
    }
    if (!this.borrowerData.additionalOption.employment) {
      this.borrowerData.additionalOption.employment = this.employment[0];
    }
  }
  public calculateLoanAmount() {
    try {
      let propertyValue = this.borrowerData.loanInformation.propertyValue;
      let loanAmount = this.borrowerData.loanInformation.loanAmount;
      let LTV = this.borrowerData.loanInformation.LTV;
      if (propertyValue) {
        let lm = (LTV * propertyValue) / 100;
        this.borrowerData.loanInformation.loanAmount = lm.toFixed(2);
      }
    } catch (error) {}
  }

  public calculateLTVAndCLTV() {
    let propertyValue = this.borrowerData.loanInformation.propertyValue;
    let loanAmount = this.borrowerData.loanInformation.loanAmount;
    if (propertyValue && loanAmount && loanAmount <= propertyValue) {
      this.borrowerData.loanInformation.LTV =
        (loanAmount / propertyValue) * 100;
      this.borrowerData.loanInformation.LTV = this.borrowerData.loanInformation.LTV.toFixed(
        2
      );
    } else
      this.borrowerData.loanInformation.CLTV = this.borrowerData.loanInformation.LTV = 0;
  }

  public setMinDownPay() {
    try {
      let pv: any = this.borrowerData.loanInformation.propertyValue;
      let mdp: any = this.borrowerData.loanInformation.minDownPayment;
      let dpt: any = this.borrowerData.loanInformation.minDownPaymentType;
      let la: any = this.borrowerData.loanInformation.loanAmount;
      

      if (pv != 0) {
        if (pv > la) {
          if (dpt == "$") {
            mdp = this.borrowerData.loanInformation.minDownPayment = pv - la;
          } else {
            mdp = this.borrowerData.loanInformation.minDownPayment =
              ((pv - la) / pv) * 100;
          }
        }

      }
    } catch (error) {
      console.log(error);
    }
  }
  async mounted() {
    await this.getBorrower();
    this.setDefaultValues();
    if (this.borrowerData.loanInformation.zipCode) {
      await this.setStateCounty(true);
    }
    await this.getBrokerDetails();
    this.setMinDownPay();
  }
}
