

import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";


@Component({
  components: { }
})
export default class DigitalMortgageComponent extends Vue {
  public isSentToBorrower = false;
  public notSentToBorrower = true;
  public inProcess = false;
  public isEmailVerified = false;

  public async isBorrowerEmailVarified() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "loan/isBorrowerEmailVarified",
        {
          loanTxnId: this.$route.query.id
        });
     if(response.data){
       this.isEmailVerified = response.data.isEmailVerified;
     }
    } catch (error) {
      console.log(error);
    }
  }
  public async sendBorrowerPortal() {
    try {
      this.$store.state.wemloLoader = true;
      this.inProcess = true;
      let response = await Axios.post(
        BASE_API_URL + "borrower/getCheckBoxInfo",
        {
          loanTxnId: this.$route.query.id
        });
      if (response.data.msg == "success") {
        this.$snotify.success("Invitation sent");
        this.isSentToBorrower = true;
        this.$router.push({query:{id: this.$route.query.id,identifier: "true"}});
        this.notSentToBorrower = false;
      } else {
        this.$snotify.error("Invitation Not Sent, Please try again");
      }
      this.inProcess = false;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.inProcess = false;
      console.log(error);
    }
  }
  //This function is for show Lets Do It modal . if doNotAskAgain checkbox is checked once then lets Do It modal will not show again.
  public async getDoNotAskAgain() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "loan/getDoNotAskAgain",
        {
          loanTxnId: this.$route.query.id,
          userId: this.$userId,
          userType: this.$userType
        });
      if (response.data.dontAsk) {
        this.$router.push({
          path: this.$getCurrentUserTypePath("broker-loan-documents"),
          query: { id: this.$route.query.id, edit: 'true' }
        });
      } else {

        this.$router.push({
          path: this.$getCurrentUserTypePath("blank-backGround-popUp"),
          query: {
            id: this.$route.query.id,
            modalName: "documentRequestModal",
            source: "Digital Mortgage Application"
          }
        });
        this.isSentToBorrower = false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public setVariables(){
    this.isSentToBorrower = true;
    this.notSentToBorrower = false;
  }

  /*****************************************************************************************************************************
   *                          Start Primary borrower POS
   *****************************************************************************************************************************/
  public async startPOS() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "common/getPrimaryBorrowerId",
        {
          loanTxnId: this.$route.query.id
        });
      if (response.data.haveQuestionnaireHistory) {
        this.$router.push({
          path: this.$getCurrentUserTypePath("pos-questionnaire"),
          query: {
            loanTxnId: this.$route.query.id,
            borrowerId: response.data.borrowerId
          }
        });
      } else {
        this.$router.push({
          path: this.$getCurrentUserTypePath("borrower-welcome"),
          query: {
            loanTxnId: this.$route.query.id,
            borrowerId: response.data.borrowerId
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    if(this.$route.query.identifier=="true")
    this.setVariables();
    this.isBorrowerEmailVarified();
  }
}
