






























































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";

import { BorrowerContact } from "@/models/borrower-contact.model";
import lodash from 'lodash';

@Component({
  components: {},
})
export default class TitleCo extends Vue {
  /****************************************************DEFINE VARIABLE HERE************************************************************ */
  private loanTxnId: String = null;
  public borrowerContact: any = new BorrowerContact();
  public titleCompanyNames = [];
  public filterTitleCompany = [];
  public isTitleCompanyNotAvl = false;
  public searchString = null;
  public selectedCompany = [];
  public currentPopover = {};
  public networkError = false;
  public isSearching = false;
  public titleLogoEnabled : boolean = null;
  public titleCompanyInfo = null;
  public activeIndex: any = null;
  public haveCompany: any = null;
  public errorMsg: boolean = false;
  public source: boolean = null;
  public manuallyAddedCompanyData = [];
  public randomTitles = [];
  public isChecked: boolean = null;
  public addedManually: boolean = null;
  public companyAddedmanually = {
    Name: "test",
    Email: "test@gmail.com",
    Phone: "12344",
    Website: "www.com",
  };
  public DefaultModelName = "Add";
  /************************************************************************************************************************************ */

  /**
   * Getting all the Title company
   */
  public async getTitleOrderComapanyName() {
    try {
      this.$store.state.wemloLoader = true;
      let response: any = await Axios.post(
        BASE_API_URL + "titleOrder/getTitleOrderComapanyName",
        {
          loanTxnId: this.loanTxnId,
        }
      );
      if (response.data) {
        this.titleLogoEnabled = response.data.enableTitleLogo;
        this.networkError = response.data.networkError;
        this.titleCompanyNames = response.data.titleCompanyData;
        this.filterTitleCompany = this.titleCompanyNames;
        this.haveCompany = response.data.haveCompany;
        this.addedManually = response.data.addedManually;
        if (this.haveCompany) {
          this.activeIndex = 0;
          this.titleCompanyInfo = this.titleCompanyNames[0];
        }
        this.getRandomThreeTitles(this.titleCompanyNames)
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.wemloLoader = false;
  }

  /**
   * Searching Title Company If this is not in the list
   */
  public searchTitleCompany() {
    try {
      let allData = this.filterTitleCompany;
      this.isSearching = true;
      if (this.searchString != "") {
        this.titleCompanyNames = allData.filter((data) => data.Name && data.Name.toLowerCase().includes(this.searchString.toLowerCase()));
        this.isTitleCompanyNotAvl = this.titleCompanyNames.length == 0 ? true : false;
      } else this.titleCompanyNames = allData;
    } catch (error) {
      console.log(error);
    }
  }


  /**
   * enable isSearchig
   */
  setSearching(){
    this.isSearching = true;
  }

  enablePopoverInTimeline(titleCompany) {
    // this.currentPopover = titleCompany;
    // this.enableTransparentOverlay();
  }

  public enableTransparentOverlay() {
    let overlay: any = this.$refs.transparent_overlay;
    if (overlay.style.display == "block") {
      overlay.style.display = "none";
    } else {
      overlay.style.display = "block";
    }
  }

  public populateUserName() {
    let userInfo = this.$store.state.sessionObject.userInfo;
    if(userInfo){
      return userInfo.firstName+ ' ' + userInfo.lastName;
    }else{
      return '';
    }
  }

  /**
   * Saving title information
   */
  public async saveTitleInformation() {
    try {
      this.source = false;
      this.$store.state.wemloLoader = true;
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        this.$store.state.wemloLoader = false;
        return;
      }
      this.borrowerContact["brokerName"] = this.populateUserName();
      this.borrowerContact.loanTxnId = this.loanTxnId;
      this.borrowerContact["userType"] = this.$store.state.sessionObject.type;
      this.borrowerContact[
        "companyAddedById"
      ] = this.$store.state.sessionObject.userId;
      let response = await Axios.post(
        BASE_API_URL + "titleOrder/saveTitleInformation/",
        this.borrowerContact
      );
      if ((response.data.msg = "success")) {
        this.$store.state.wemloLoader = false;
        this.source = true;
        this.$snotify.success("Title company added successfully");
        this.$modal.hide("TitleCompanyModal");
        await this.getTitleOrderComapanyName();
      }
      this.errorMsg = false;
    } catch (error) {
      console.log(error);
    }
  }

  public checkManuallyAddedCompany() {
    try {
      if (!this.isChecked && this.addedManually) {
        this.activeIndex = null;
      }
      this.source = false;
      this.isChecked = true;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * To select title company  ********************************
   */
  public selectTitleCompany(titleCompany, index) {
    try {
      this.isChecked = false;
      this.source = true;
      this.activeIndex = index;
      this.titleCompanyInfo = titleCompany;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * To edit Manually added title company
   */
  public editTitleCompany() {
    try {
      this.$modal.show("TitleCompanyModal");
      this.DefaultModelName = "Edit";
    } catch (error) {
      console.log(error);
    }
  }

  public getRandomThreeTitles(titles = []){
    let randomTitles = [];
    const newTitles = lodash.cloneDeep(titles);
    if(titles.length) randomTitles = newTitles.sort(() =>  .5 - Math.random()).slice(0,3);
    this.randomTitles = randomTitles;
  }

  /**
   * For moving forward
   */
  public async submit() {
    this.$store.state.wemloLoader = true;
    try {
      //In case of manual pass token of first title
      let firstTitle = this.titleCompanyNames.find(company => company.Token);
      if(this.titleCompanyInfo.borrowerContactId && firstTitle){
        this.titleCompanyInfo.Token = firstTitle.Token;
      }
      if (!this.titleCompanyInfo && !this.isChecked) {
        this.errorMsg = true;
        this.$store.state.wemloLoader = false;
        return false;
      }
      this.errorMsg = false;
      if (!this.source) return;
      let response = await Axios.post(
        BASE_API_URL + "titleOrder/selectedTitleCompany",
        {
          loanTxnId: this.loanTxnId,
          companyDetails: this.titleCompanyInfo
            ? this.titleCompanyInfo
            : this.filterTitleCompany[0],
          userId: this.$store.state.sessionObject.userId,
          userType: this.$store.state.sessionObject.userType,
          source: this.source,
          borrowerContactId :  this.titleCompanyInfo.borrowerContactId
        }
      );
      return true;
    } catch (error) {
      this.$snotify.error("Something went wrong! Please try after sometime.");
      console.log(error);

      /**
       * Must return false if throw some error
       */
      return false;
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  async mounted() {
    this.loanTxnId = String(this.$route.query.id);
    await this.getTitleOrderComapanyName();
  }
}
