








































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Datepicker from "vuejs-datepicker";
import DisplayFiles from "@/views/DisplayDocument.vue";
import { BASE_API_URL } from "@/config";
import Axios from "axios";

import { borrowerData } from "@/models/borrowerData.model";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";

@Component({
  components: {
    Datepicker,
    DisplayFiles
  }
})
export default class Fees extends Vue {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  private disableDate: any = {};
  private counts: number = 0;
  private attachment: Array<any> = [];
  private commentAttachment: Array<any> = [];
  private state: any = {};
  private borrowerData: any = new borrowerData();
  private loanTxnId: String = null;
  private showError: boolean = false;
  private fileName: any = {};
  private showFileName: boolean = false;
  private lockDayError : boolean = false;
  private isFileAttached : boolean = false;

  private restoreDisableDates() {
    let date = new Date().setDate(new Date().getDate() - 1);
    this.state = {
      disabledDatesFrom: {
        to: new Date(date)
      },
      disabledDatesFromOne: {
        to: new Date(date)
      },
      disabledDatesFromTwo: {
        to: new Date(date)
      },
      disabledDatesTo: {
        to: new Date(date)
      }
    };
  }

  /****For disabling date w.r.t other one *****/
  private disablingToDate() {
    if (this.borrowerData.updatedRateLockDate != null) {
      let fromDateOne = new Date(this.borrowerData.updatedRateLockDate);
      this.state.disabledDatesFromOne.to = new Date(
        fromDateOne.setDate(fromDateOne.getDate() + 1)
      );
    }
    if (this.borrowerData.updatedRateExpirationDate != null) {
      let fromDateTwo = new Date(this.borrowerData.updatedRateExpirationDate);
      this.state.disabledDatesFromTwo.to = new Date(
        fromDateTwo.setDate(fromDateTwo.getDate() + 1)
      );
    }
  }

  /*********For attaching rate lock file**********/
  private attachRateLockFile(event) {
    if(event.target.files.length == 0) return
    this.attachment = event.target.files;
    this.commentAttachment = [];
    this.counts = 0;
    Object.values(this.attachment).forEach(e => {
      this.commentAttachment.push(e);
      this.counts++;
    });
    let files: any = this.$refs.abc;
    this.fileName = files.files[0];
    this.showFileName = true;
    this.isFileAttached = true
  }

  

  /*********For viewing rate lock file**********/
  private viewAttachment() {
    if (this.commentAttachment[0].hasOwnProperty("path"))
      this.$refs.dfl["setInitialValuesForShowingSamples"](
        null,
        this.commentAttachment,
        null
      );
    else
      this.$refs.dfl["setInitialValuesForLocalFiles"](
        this.commentAttachment,
        true,
        "commentAttachment"
      );
  }

  /****For deleting rate lock file locally****/
  private deleteDoc(from) {
    try {
      this[from.deleteData].splice(from.index, 1);
      if (from.deleteData == "commentAttachment") {
        this.counts--;
        let file: any = this.$refs.abc;
        file.value = null;
        this.fileName = {};
        this.showFileName = false;

      }
    } catch (error) {
      console.log(error);
    }
  }

  /****For uploading ratelockfile****/
  private async rateLockFileUpload() {
    try {
      let formData = new FormData();
      if (this.attachment) {
        Object.values(this.attachment).forEach((e, i) => {
          formData.append("file", this.attachment[i]);
        });
      }

      let data: any = {
        loanTxnId: this.$route.query.id,
        rateType: this.borrowerData.loanInformation.rateType,
        userType: this.$userType
      };
      formData.append("data", JSON.stringify(data));
      await Axios.post(
        BASE_API_URL + "loan/rateLockFileUpload",
        formData);
      this.clearRateLockData('clearData');
      this.borrowerData = new borrowerData();
    } catch (error) {
      console.log(error);
    }
  }

  private clearRateLockData(calledFrom) {
    this.attachment = [];
    this.commentAttachment = [];
    this.counts = 0;
    this.showFileName = false;
    this.fileName = {};
    if(calledFrom=='clearData'){
    this.borrowerData.updatedRateExpirationDate = null;
    this.borrowerData.updatedRateLockDate = null;
    }
  }

  private addRateType(type) {
    this.borrowerData.loanInformation.rateType = type;

    if (type != "Lock Rate") {
      this.clearRateLockData('clearData');
    }

    if (type != "Lock") {
      this.borrowerData.lockRateDays = 0;
    }

    this.lockDayError=false

  }

  /******For saving fees page details******/
  private async saveFeeDetails() {
    let response = await Axios.post(
      BASE_API_URL + "broker/getAndSaveFeeDetails",
      {
        loanTxnId: this.$route.query.id,
        identifier: "save",
        borrowerData: this.borrowerData
      });
  }

  /******For fetching fees page details if already saved***** */
  private async getFeesDetails() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "broker/getAndSaveFeeDetails",
        {
          loanTxnId: this.$route.query.id,
          identifier: "fetch"
        });
      this.borrowerData = response.data.data;
      if (this.borrowerData.loanInformation.compensationType == ""){
        this.borrowerData.loanInformation.compensationType = 'Borrower Paid Compensation';
        this.borrowerData.loanInformation.compensationValType = '$'
      }
      if (this.borrowerData.loanInformation.rateType == "Lock Rate") {
        this.counts = response.data.file
          ? response.data.file.fileHistory.length
          : 0;

        if (response.data.file) {
          this.attachment = response.data.file.fileHistory[0].files;
          await this.getUploadedFileSize(this.attachment);
          Object.values(this.attachment).forEach(e => {
            this.commentAttachment.push(e);
          });
        } else {
          this.commentAttachment = [];
        }
      }
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;

      console.log(error);
    }
  }

  public async submit() {
    try {
      this.$store.state.wemloLoader = true;
      this.validateRateType()
      let formValid = await this.$validator.validateAll();
      if (!formValid || this.showError ||this.lockDayError) {
        this.$store.state.wemloLoader = false;
        let $el = this.$refs.compensationType as any;
        let $errors = $el.querySelectorAll('.error_text');
        let $firstError;
        if($errors){
          $errors.forEach(element => {
            if(!$firstError){
              if(window.getComputedStyle(element).display !== "none"){
                $firstError = element;
              }
            }
            return $firstError;
            
          });
          if($firstError){
            $firstError.scrollIntoView(false);
          }
        }
        return false;
      }
      await this.saveFeeDetails();
      if (this.borrowerData.loanInformation.rateType == "Lock Rate" && this.isFileAttached) {
        await this.rateLockFileUpload();
      }
      this.$snotify.success("Fees saved successfully");
      this.showError = false;
      return true;
    } catch (error) {
      this.$snotify.error("Something went wrong! Please try after sometime.");
      console.log(error);

      /**
       * Must return false if throw some error
       */
      return false;
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }
  getFileSize(bytes) {
    let fileSize: Number = 0;
    if (bytes < 1024 * 1024) {
      fileSize = bytes / 1024;
      return fileSize.toFixed(2) + " kb";
    } else if (bytes > 1024 * 1024) {
      fileSize = bytes / (1024 * 1024);
      return fileSize.toFixed(2) + " mb";
    }
  }
private async getUploadedFileSize(details) {
            try { 
             let data= details
               let response = await Axios.post(BASE_API_URL + 'common/fetchDocument', {
                    path: data[0].path,
                    calledFrom:'fees'
                });
                this.fileName = {name:data[0].originalName,size:response.data.size}
                this.showFileName = true;
            } catch (error) {
                console.log(error);
            }
        }
private validateRateType(){
   if (this.borrowerData.loanInformation.compensationType == "" ||this.borrowerData.loanInformation.rateType == null){
        this.showError = true;
        if(this.borrowerData.lockRateDays==0) this.lockDayError=true;
          
   }
      else {
        if(this.borrowerData.loanInformation.rateType=='Lock'&&this.borrowerData.lockRateDays==0)
          this.lockDayError=true
        else{
          this.showError = false;
          this.lockDayError=false

       } }
}

onChangePercent(val){
  this.borrowerData.loanInformation.compensationDollars = this.borrowerData.loanInformation.loanAmount*val/100;
}

onChangeDollars(val){
  this.borrowerData.loanInformation.compensation = (val/this.borrowerData.loanInformation.loanAmount)*100;
}

async mounted() {
    this.getFeesDetails();
    this.restoreDisableDates();
    this.loanTxnId = String(this.$route.query.id);
  }
}
