






































import {
    Component,
    Vue,
    Prop
} from "vue-property-decorator";
@Component({
    components: {}
})
export default class Processing extends Vue {
    private loanTxnId: String = null;

    public async submit() {
        try {
            this.$snotify.success("Loan submitted successfully");
            this.$router.push({
                path: this.$getCurrentUserTypePath('dashboard')
            })
        } catch (error) {
            console.log(error);
        }
    }

    async mounted() {
        this.loanTxnId = String(this.$route.query.id);
    }
}
