







import { Component, Vue, Prop } from "vue-property-decorator";
import DisclosuresPackage from "@/components/disclosures-package/DisclosuresPackage.vue";

@Component({ components: { DisclosuresPackage } })
export default class BorrowerDisclosures extends Vue {
    @Prop() borrowerInfo;

    public async submit(){
        if(this.$refs.disclosuresPackage && this.$refs.disclosuresPackage['submit']) {
            return await this.$refs.disclosuresPackage['submit']()
        }
    }

    public async triggersubmit(toNextStep = true){
        this.$emit('triggersubmit', toNextStep);
    }
}
