

import { Component, Vue, Prop } from "vue-property-decorator";
import { HollowDotsSpinner } from "epic-spinners";
import { BASE_API_URL } from "@/config";
import Axios from "axios";
import { borrowerData } from "@/models/borrowerData.model";
import { BorrowerInformation } from "@/models/pos/borrower-info.model";
import { LoanAndProperty } from "@/models/pos/loan-and-property.model";
import { Declaration } from "@/models/pos/declaration.model";
import { DemographicInformation } from "@/models/pos/demographic-info.model";
import { FinancialInfo } from "@/models/pos/financial-info.model";
import { Acknowledgement } from "@/models/pos/acknowledgement.model";
import { LoanOriginatorInfo } from "@/models/pos/loan-originator-info.model";
import { TransactionDetail } from "@/models/pos/transaction-detail.model";
import _ from "lodash";


@Component({
  components: { HollowDotsSpinner }
})
export default class AddMismoBorrowerInfoComponent extends Vue {
  @Prop()
  public borrowers;
  @Prop()
  public ssn;
  @Prop()
  public dataType: any;
  @Prop()
  public mappingInfo: any;
  public duplicate: Boolean = false;
  public borrowerData = new borrowerData();
  public borrowerInformation = new BorrowerInformation();
  public loanAndProperty = new LoanAndProperty();
  public declaration = new Declaration();
  public demographicInformation = new DemographicInformation();
  public financialInfo = new FinancialInfo();
  public acknowledgement = new Acknowledgement();
  public loanOriginatorInfo = new LoanOriginatorInfo();
  public transactionDetail = new TransactionDetail();
  public selectedDatatype: any = null;
  public borrowerInfo = [];
  public dontAsk = false;
  public disableButton = false;
  public mismoJson: any = {};

  public async duplicateMailAndPhone() {
    try {
      this.duplicate = false;
      this.borrowerInfo.forEach(element => { element.duplicateEmail = false; });
      this.borrowerInfo.forEach((data, i) => {
        this.borrowerInfo.forEach((e, j) => {
          if (i != j) {
            if (
              data.personalInfo.contactInformation.email ==
              e.personalInfo.contactInformation.email
            ) {
              this.duplicate = true;
              e.duplicateEmail = true;
              this.$store.state.wemloLoader = false;
            }
          }
        });
      });
      this.borrowerInfo = JSON.parse(JSON.stringify(this.borrowerInfo));
      if (this.duplicate) {
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }

  notifyDiscardedFields = (discardedFields = []) => {

    discardedFields.forEach(({ label, value }) => {
      if (!label) { return; }

      this.$snotify.warning(`Field ${label} was discarded because it had an invalid value of ${value}`, { timeout: 5000 });
    });
  }

  public async saveMismoFile() {
    this.$store.state.wemloLoader = true;
    this.disableButton = true;

    try {

      const formValid = await this.$validator.validateAll();

      if (!formValid) {
        this.$store.state.wemloLoader = false;
        this.disableButton = false;
        return;
      }

      await this.duplicateMailAndPhone();

      if (this.duplicate) { return }

      const info  = {
        borrowerData: this.borrowerData,
        borrowerInfo: this.borrowerInformation,
        loanAndProperty: this.loanAndProperty,
        declaration: this.declaration,
        demographicInformation: this.demographicInformation,
        financialInfo: this.financialInfo,
        acknowledgement: this.acknowledgement,
        transactionDetail: this.transactionDetail,
        // relationshipMapping:
        //   this.selectedDatatype == "currentData" ? mapping : null
      };

      const body = {
        borrowerInfo: this.borrowerInfo,
        ediFile: this.$route.query.ediFile,
        brokerId: this.$brokerId,
        info,
        addedBy: this.$userId,
        userType: this.$userType,
        ssnToDelete: this.selectedDatatype == "currentData" ? this.ssn : null,
        dataType: this.selectedDatatype,
        source: this.$route.query.source,
        fileData: this.mismoJson
      };

      const { data: { success, loan, discardedFields = [] } } = await Axios.post(
        BASE_API_URL + "loan/readMismoFile",
        body
      ) || {};

      if (!success) {
        this.borrowerInfo = JSON.parse(JSON.stringify(loan.borrowerInfo));
        this.$store.state.wemloLoader = false;
        this.disableButton = false;

        return;
      }

      const loanTxnId = loan.loanTxnId;

      this.notifyDiscardedFields(discardedFields);
      this.$snotify.success("Saved successfully");

      if (this.dontAsk) {
        this.$router.push({
          path: this.$getCurrentUserTypePath("broker-loan-documents"),
          query: { id: loanTxnId, edit: 'true' }
        });
      } else {
        this.$router.push({
          path: this.$getCurrentUserTypePath("blank-backGround-popUp"),
          query: {
            id: loanTxnId,
            modalName: "documentRequestModal",
            source: "Add Fnm Borrower"
          }
        });
      }

    } catch (error) {
      console.log(error);

      this.$router.push({
        path: this.$getCurrentUserTypePath("import-3.4"),
        query: { id: this.$route.query.id, source: "import-3.4" }
      });
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  public addDuplicateVariables() {
    try {
      this.borrowerInfo.forEach(element => { element["duplicateEmail"] = false; });
    } catch (error) {
      console.log(error);
    }
  }

  public async getMismoBorrowerInfo() {
    this.$store.state.wemloLoader = true;

    try {

      const { data: { data: { borrowerInfo } } } =
        await Axios.post(
          `${BASE_API_URL}loan/getMismoBorrowerInfoReading`,
          { fileData: this.mismoJson }
        );

      if (_.size(borrowerInfo)) {

        this.borrowerInfo = borrowerInfo;

      } else {
        this.$snotify.error("Your xml file seems to be incorrect. Please verify the file and upload it again");

        this.$router.push({
          path: this.$getCurrentUserTypePath("import-3.4"),
          query: { id: this.$route.query.id, source: "import-3.4" }
        });
      }

      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$router.push({
          path: this.$getCurrentUserTypePath("import-3.4"),
          query: { id: this.$route.query.id, source: "import-3.4" }
        });
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  public async getDontAsk() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "loan/getDoNotAskAgain",
        {
          userId: this.$userId,
          userType: this.$userType
        }
      );
      this.dontAsk = response.data.dontAsk;
    } catch (error) {
      console.log(error);
    }
  }

  public async convertToMismoJson() {
    try {
      const response = await Axios.post(
        BASE_API_URL + "mismo/convertToMismoJson",
        {ediFile: this.$route.query.ediFile}
      );
      this.mismoJson = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    if (this.$route.query.source == "import-3.4") {
      await this.convertToMismoJson();
      this.selectedDatatype = this.dataType;
      await this.getMismoBorrowerInfo();
      this.selectedDatatype = 'mismo';
    } else {
      this.borrowerInfo = this.borrowers;
      this.selectedDatatype = this.dataType;
      this.addDuplicateVariables();
    }
    await this.getDontAsk();
  }
}
