













































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import { Multiselect } from "vue-multiselect";
import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
import StarRating from "vue-star-rating";
import ProfilePicture from "@/views/ProfilePicture.vue";

@Component({
  components: {
    Multiselect,
    StarRating,
    ProfilePicture,
  }
})
export default class Processing extends mixins(CommonMixin) {
  private loanTxnId: String = null;
  private activateWemBal: boolean = false;
  private deactivateWemSelProcessors: boolean = false;
  // public confirmActivateWemloBalencing = false;
  private brokerProcessorList: Array<any> = [];
  private wemloProcessorList: Array<any> = [];
  private roles: Array<any> = [];
  private selectedRole: Array<any> = [];
  private brokerSelectedProcessor: Array<any> = [];
  private wemloSelectedProcessor: Array<any> = [];
  private userRatings: Array<any> = [];
  private validation: Array<any> = [];
  public message: String = "";
  private activateWemPro: boolean = true;
  private hasLoanSubmitted: boolean = false;
  private hasLoan: boolean = false;
  private allProcessors: Array<any> = [];
  private loader: boolean = true;
  private width: number = 0.8;
  private nameOfCurrentProcessor: String = null;
  private profilepicture = null;

  @Prop() public changesInLE;
  // public s: any = {};

  /**
   * To get the list of all processor which is added by wemlo team
   */
  public async getWemloProcessorList() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/getProcessorList",
        {
          userId: this.$brokerId,
          type: "wemloProcessor"
        });
      this.wemloProcessorList = response.data;
    } catch (error) {
      console.log(error, "getWemloProcessorList");
    }
  }
  /**
   * To get the list of all processor which is added by broker
   */
  public async getBrokerProcessorList() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/getProcessorList",
        {
          userId: this.$brokerId,
          type: "brokerProcessor"
        });

      this.brokerProcessorList = response.data;
    } catch (error) {
      console.log(error, "getBrokerProcessorList");
    }
  }
  /**
   * filtering the roleId from  this.brokerSelectedProcessor and this.processorlist if this roleid is exit
   */
  public async checkIfRolesExist(selectedRole, processorId, type) {
    try {
      if (type == "Broker") {
        this.brokerSelectedProcessor = await this.brokerSelectedProcessor.filter(
          a => !(a.roleId == selectedRole.id)
        );
        await this.allProcessors.forEach(async e => {
          if (e.userId != processorId && !e.wemloMember)
            e.selectedRole = await e.selectedRole.filter(
              sr => sr.id != selectedRole.id
            );
        });
      } else {
        await this.allProcessors.forEach(async e => {
          if (e.userId != processorId && e.wemloMember)
            e.selectedRole = await e.selectedRole.filter(
              sr => sr.id != selectedRole.id
            );
        });
      }
    } catch (error) {
      console.log(error, "checkIfRolesExist");
    }
  }

  public async enableDisableCondition(type) {
    if (type == "broker") {
      this.wemloSelectedProcessor = [];
      if (this.brokerSelectedProcessor.length > 0)
        this.allProcessors = this.allProcessors.filter(e => !e.wemloMember);
      else {
        await this.toGetAllProcessors();
      }
    } else {
      this.brokerSelectedProcessor = [];
      if (this.wemloSelectedProcessor.length > 0) {
        this.allProcessors = this.allProcessors.filter(e => e.wemloMember);
        this.roles = this.roles.filter(e => e.id == 1);
      } else {
        await this.toGetAllProcessors();
        await this.getRoles();
      }
    }
  }

  public async changeValue(selectedProcessor, checked) {
    try {
      if (selectedProcessor.wemloMember)
        await this.changeValueForWemloProcessor(selectedProcessor, checked);
      else await this.changeValueForBrokerProcessor(selectedProcessor, checked);
    } catch (error) {
      console.log(error);
    }
  }

  public async changeValueForBrokerProcessor(selectedProcessor, checked) {
    try {
      this.wemloSelectedProcessor = [];
      await this.allProcessors.forEach(async e1 => {
        if (e1.userId == selectedProcessor.userId) {
          if (checked) {
            await this.toStoreData(e1, "Broker");
            await this.enableDisableCondition("broker");
          } else {
            await this.toFilterRoles(selectedProcessor);
            this.brokerSelectedProcessor = await this.brokerSelectedProcessor.filter(
              a => selectedProcessor.userId != a.processorId
            );
            await this.enableDisableCondition("broker");
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async changeValueForWemloProcessor(selectedProcessor, checked) {
    try {
      this.wemloSelectedProcessor = [];
      await this.allProcessors.forEach(async e1 => {
        if (e1.userId == selectedProcessor.userId) {
          if (checked) {
            await this.toStoreData(e1, "Wemlo");
            // await this.enableDisableCondition("wemlo");
          } else {
            await this.toGetAllProcessors();
            await this.toFilterRoles(selectedProcessor);
            this.wemloSelectedProcessor = await this.brokerSelectedProcessor.filter(
              a => selectedProcessor.userId != a.processorId
            );
            // await this.enableDisableCondition("wemlo");
          }
        }
      });
    } catch (error) {
      console.log(error, "changeValueForWemloProcessor");
    }
  }

  public async toFilterRoles(selectedProcessor) {
    try {
      let roles = await this.roles.map(e => {
        if (
          e &&
          e.processorData &&
          e.processorData.processorId &&
          e.processorData.processorId == selectedProcessor.userId &&
          e.checked
        ) {
          e.processorData = {};
          e.checked = null;
          e.id = null;
          e.name = null;
        }
        return e;
      });
      // this.selectedRole = await Promise.all(roles);
      // this.selectedRole = await this.selectedRole.filter(
      //   e =>
      //     !(
      //       e.processorData.processorId.toString() ===
      //       selectedProcessor.userId.toString()
      //     )
      // );
    } catch (error) {
      console.log(error);
    }
  }

  public async toStoreData(e1, type) {
    try {
      await e1.selectedRole.forEach(async element => {
        await this.checkIfRolesExist(element, e1.userId, type);
        if (type == "Wemlo")
          await this.wemloSelectedProcessor.push({
            brokerId: this.$userId,
            roleId: element.id,
            processorId: e1.userId
          });
        else
          await this.brokerSelectedProcessor.push({
            brokerId: this.$userId,
            roleId: element.id,
            processorId: e1.userId
          });
        /************************************************************************
         *                      DO NOT REMOVE THE CODE                          *
         *                                                                      *
         ************************************************************************/
        await this.getRoles();
        let rolesData = await this.roles.map(async e => {
          if (e.id == element.id) {
            e["processorData"] = {
              processorId: e1.userId,
              profilePicture: e1.profilePicture,
              firstLetterOfname: e1.firstLetterOfName,
              checked: true,
              selectedProcessorName:
                e1.firstName + " " + (e1.lastName ? e1.lastName : " "),
              wemloMember: e1.wemloMember,
              processorRelationship: e1.wemloMember
                ? "Wemlo"
                : e1.processorRelationship
            };
            e["checked"] = true;
          }
          return e;
        });
        this.roles = await Promise.all(rolesData);
        // this.selectedRole;
        // let x: any = {};
        // await this.roles.forEach(async e => {
        //   if (e.id == element.id) {
        //     x.processorData = {
        //       processorId: e1.userId,
        //       profilePicture: e1.profilePicture,
        //       firstLetterOfname: e1.firstLetterOfName,
        //       checked: true,
        //       selectedProcessorName:
        //         e1.firstName + " " + (e1.lastName ? e1.lastName : " "),
        //       wemloMember: e1.wemloMember,
        //       processorRelationship: e1.wemloMember
        //         ? "Wemlo"
        //         : e1.processorRelationship
        //     };
        //     x.checked = true;
        //     x.id = e.id;
        //     x.name = e.name;
        //     await this.selectedRole.push(x);
        //   }
        // });
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * To get roles to display
   */
  public async getRoles() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "broker/getRolesForProcessorList");
      this.roles = response.data.roles;
      // this.roles = await this.roles.filter(e => e.id != 8 && e.id != 9);
      this.roles = await this.roles.filter(e => e.id == 1);
    } catch (error) {
      console.log(error, "getRolesName");
    }
  }

  /**
   * To check if any processor  doesnot have any role
   */
  public async validate() {
    try {
      this.validation = this.roles.filter(
        e =>
          (e.hasOwnProperty("checked") && !e.checked) ||
          !e.hasOwnProperty("checked")
      );
      if (this.validation.length > 0) return false;
      else return true;
    } catch (error) {
      console.log(error, "validate");
    }
  }
  /**
   * Saving processorId against role
   */
  public async submit() {
    try {
      this.$store.state.wemloLoader = true;
      if (
        !this.activateWemBal &&
        this.brokerSelectedProcessor.length == 0 &&
        this.wemloSelectedProcessor.length == 0
      ) {
        this.$snotify.error("Please select Processor");
        this.$store.state.wemloLoader = false;
        return false;
      }
      if (this.brokerSelectedProcessor.length > 0) {
        let validate = await this.validate();
        if (!validate) {
          this.$snotify.error("Please assign Processor to all task");
          this.$store.state.wemloLoader = false;
          return false;
        }
      }
      if (this.changesInLE) {
        this.$snotify.info("Please go back and  Approve LE");
        this.$store.state.wemloLoader = false;
        return false;
      }
      let isWemloRoleAllocation;
      let selectedProcessor: any = [];
      if (this.brokerSelectedProcessor.length > 0) {
        selectedProcessor = this.brokerSelectedProcessor;
        isWemloRoleAllocation = false;
      } else if (this.wemloSelectedProcessor.length > 0) {
        selectedProcessor = this.wemloSelectedProcessor;
        isWemloRoleAllocation = true;
      } else isWemloRoleAllocation = true;
      this.hasLoanSubmitted = true;
      let response = await Axios.post(
        BASE_API_URL + "broker/saveProcessorOrSubmitToWemlo",
        {
          brokerStaff: selectedProcessor,
          userId: this.$userId,
          loanTxnId: this.$route.query.id,
          userType: this.$userType,
          isWemloRoleAllocation: isWemloRoleAllocation
        });
      if (response.data.message) {
        this.hasLoan = true;
        this.$snotify.error(response.data.message);
        this.hasLoanSubmitted = false;
      } else {
        this.$snotify.success("Loan submitted successfully");
        this.hasLoanSubmitted = false;
        //this.$router.push("/dashBoard");
      }
      return true;
    } catch (error) {
      this.hasLoanSubmitted = false;

      this.$snotify.error("Something went wrong! Please try after sometime.");
      console.log(error);

      /**
       * Must return false if throw some error
       */
      return false;
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }
  /**
   * Average ratings and reviews of processor respective to broker and mlo
   */
  public async ratingsAndReviews(id) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/ratingsAndReviews",
        {
          id: id
        });
      this.userRatings = response.data;
    } catch (error) {
      console.log(error, "ratingsAndReviews");
    }
  }
  /**
   * Making  Processor Favorite
   */
  public async makingProcessorFavorite(val, i, pId) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/makingProcessorFavorite",
        {
          userId: this.$userId,
          userType: this.$userType,
          processorId: pId,
          favorite: val
        });
    } catch (error) {
      console.log(error, "makingProcessorFavorite");
    }
  }

  public async activateWemloBalancing(val) {
    try {
      this.activateWemBal = val;
      if (val) {
        // this.selectedRole = [];
        this.wemloSelectedProcessor = [];
        this.brokerSelectedProcessor = [];
        this.deactivateWemSelProcessors = true;
      }
      if (!this.activateWemBal) {
        this.wemloSelectedProcessor = [];
        this.brokerSelectedProcessor = [];
        this.deactivateWemSelProcessors = false;
        await this.toGetAllProcessors();
        await this.getRoles();
      }
    } catch (error) {
      console.log(error, "activateWemloBalancing");
    }
  }
  /**
   * To keep both user's processor in one variable
   */
  public async toGetAllProcessors() {
    try {
      await this.getBrokerProcessorList();
      await this.getWemloProcessorList();
      this.loader = true;
      this.allProcessors = [];
      if (this.brokerProcessorList.length > 0) {
        await this.brokerProcessorList.forEach(async e => {
          await this.allProcessors.push(e);
        });
      }
      if (this.wemloProcessorList.length > 0) {
        await this.wemloProcessorList.forEach(async e => {
          await this.allProcessors.push(e);
        });
      }
      this.loader = false;
    } catch (error) {
      this.loader = false;

      console.log(error);
    }
  }

private async loadImage(path, i){
    try {
      let response = await Axios.post(BASE_API_URL+'common/fetchDocument', { path: path });
      let dom = document.getElementById(i!=undefined?'imageEl' + i : 'imageEl');
      dom['src'] = 'data:image/'+path.split('.').pop()+';base64,'+response.data;
      dom.append();
    } catch (error) {
      console.log(error);
    }
  }
  async mounted() {
    this.loanTxnId = String(this.$route.query.id);
    await this.getRoles();
    await this.toGetAllProcessors();
    await this.$emit("checkChangesInLe");
  }
}
